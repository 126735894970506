@media (min-width: 320px) {
  .pdtb5 {
    padding: 20px 0; } }
@media (min-width: 768px) {
  .pdtb5 {
    padding: 50px 0; } }

.list-group-item i {
  margin-top: 8px; }

#first-panel {
  border-color: #ddd;
  border-radius: 0px;
  border-bottom-width: initial;
  border-bottom-color: #6B9FD9; }

#second-panel {
  border-color: #ddd;
  border-radius: 0px;
  border-bottom-width: initial;
  border-bottom-color: #dd286b; }

#third-panel {
  border-color: #ddd;
  border-radius: 0px;
  border-bottom-width: initial;
  border-bottom-color: #ffc06e; }

#frth-panel {
  border-color: #ddd;
  border-radius: 0px;
  border-bottom-width: initial;
  border-bottom-color: #423f3f; }

address i {
  margin-top: 5px; }

#quality .thumbnail {
  min-height: 325px; }

@media (min-width: 320px) and (max-width: 480px) {
  h1 {
    font-size: 35px; }

  .testi {
    font-size: 16px !important;
    padding: 10px 20px; } }
@media (min-width: 320px) {
  section .container {
    padding: 20px 15px !important; } }
@media (min-width: 768px) {
  section .container {
    padding: 50px 15px !important; } }

section.footer .copyright .container {
  padding: 0 !important; }

.inner-page .container {
  padding: 0; }

#footer .fa {
  display: inline; }
#footer a {
  color: #ccc; }
#footer form input {
  margin-bottom: 0; }
#footer form textarea {
  margin-bottom: 0; }
#footer .logo img {
  float: left;
  line-height: 55px;
  margin: 0px;
  padding: 0px;
  font-size: 70px;
  color: #ffffff; }
#footer .list-unstyled1 a {
  color: #ccc; }
#footer .copyright .message {
  font-size: 14px; }
#footer .copyright a {
  color: #999; }

section.header .main-wrap h1 {
  margin-bottom: 5px; }
  @media (min-width: 320px) {
    section.header .main-wrap h1 {
      font-size: 15px; } }
  @media (min-width: 768px) {
    section.header .main-wrap h1 {
      font-size: 45px; } }

section.header .main-wrap {
  height: auto;
  position: absolute;
  z-index: 20;
  bottom: 10%; }

.service .service-wrap .info {
  padding: 15px 10px;
  text-align: center; }

section .subheading {
  text-align: center;
  font-size: 18px;
  line-height: 30px;
  padding: 30px;
  max-width: 1000px;
  margin: 0 auto; }

.service .service-wrap .pic .more a {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: inline-block;
  height: 30px;
  padding: 0 11px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  text-decoration: none;
  z-index: 4;
  background-color: #5BC0DE; }

section .headul {
  margin: 10px auto 20px auto; }

@media (min-width: 320px) and (max-width: 767px) {
  section.header .topbar-wrap {
    padding-top: 5px; } }
@media (min-width: 768px) {
  section.header .topbar-wrap {
    padding-top: 5px; } }

section.header .topbar-wrap .logo-area {
  min-width: 0;
  padding-left: 0px;
  padding-right: 0px; }

section .bg-overlay {
  background-color: rgba(244, 67, 54, 0.24);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1; }

.cssmenu ul ul li a {
  width: 215px; }

section.header .header-bg {
  display: block;
  overflow: hidden; }

section.header .main-wrap .container .main-content {
  padding-left: 0; }

@media (min-width: 320px) and (max-width: 767px) {
  section.header .header-small .main-wrap {
    display: none; } }
@media (min-width: 768px) {
  section.header .header-small .main-wrap {
    display: block;
    top: 50%; }
    section.header .header-small .main-wrap h1 {
      margin-bottom: 5px;
      font-size: 30px;
      line-height: 30px; } }
.page-header {
  padding-bottom: 10px;
  margin: 0 0 20px;
  border-bottom: 1px solid #eee; }

p {
  text-align: justify; }

#input1 {
  width: 33%; }

  input[type="radio"] {
    height: 18px;
     margin-left: 0!important; 
}
