.logo {
  margin-top: 5px; }
  @media (min-width: 320px) and (max-width: 480px) {
    .logo {
      width: 260px; } }

section .bg-overlay {
  background-color: rgba(10, 10, 10, 0.26);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1; }

.cssmenu > ul > li > a {
  padding: 0 8px;
  line-height: 50px;
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  color: #333;
  text-align: center;
  background-color: transparent; }

.cssmenu ul ul li a {
  background-color: #607D8B; }

section.header .topbar .menu ul li:hover {
  background-color: #607D8B; }

.service .service-wrap .info {
  padding: 15px 10px;
  text-align: center;
  min-height: inherit; }

.service .service-wrap .info .title {
  margin-bottom: 0;
  text-transform: none;
  font-size: 110%; }

textarea {
  resize: none; }

section.testimonials {
  background-image: url("../images/testimonials-bg.jpg");
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center; }

section.header .header-bg .bg-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  z-index: 2;
  top: 0px;
  left: 0px;
  background-color: rgba(33, 33, 33, 0.69); }

#quality .thumbnail {
  min-height: 280px; }

#request form .form-control {
  margin-bottom: 0;
  color: #9E9E9E !important; }
#request form textarea {
  resize: none; }

.rwd-table {
  margin: 1em 0;
  min-width: 100%; }

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd; }

.rwd-table th {
  display: none; }

.rwd-table td {
  display: block;
  color: #fff; }

.rwd-table td:first-child {
  padding-top: .5em; }

.rwd-table td:last-child {
  padding-bottom: .5em; }

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 6.5em;
  display: inline-block; }

@media (min-width: 480px) {
  .rwd-table td:before {
    display: none; } }
.rwd-table th, .rwd-table td {
  text-align: left; }

@media (min-width: 480px) {
  .rwd-table th, .rwd-table td {
    display: table-cell;
    padding: .25em .5em; }

  .rwd-table th:first-child, .rwd-table td:first-child {
    padding-left: 0; }

  .rwd-table th:last-child, .rwd-table td:last-child {
    padding-right: 0; } }
/*h1 {
  font-weight: normal;
  letter-spacing: -1px;
  color: #00B7B1;
}*/
.rwd-table {
  background: #34495E;
  color: #fff;
  border-radius: .4em;
  overflow: hidden; }

.rwd-table tr {
  border-color: #00B7B1; }

.rwd-table th, .rwd-table td {
  margin: .5em 1em; }

@media (min-width: 480px) {
  .rwd-table th, .rwd-table td {
    padding: 1em !important; } }
.rwd-table th, .rwd-table td:before {
  color: #fff; }

#branch i {
  vertical-align: baseline; }
#branch h5 {
  line-height: 20px; }
  #branch h5 span {
    font-size: 90%;
    font-weight: 500; }
  #branch h5 small {
    line-height: 2; }
#branch a i {
  vertical-align: baseline; }

#clients .thumbnail img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); }
#clients .owl-carousel .owl-wrapper-outer {
  box-shadow: none; }

#instant-callback-div {
  font-family: "Open Sans",Arial,sans-serif;
  padding: 0 0 24px;
  position: fixed;
  right: -245px;
  top: 40%;
  font-size: 95%;
  vertical-align: top;
  z-index: 9999; }
  #instant-callback-div .btn-primary.btn-block {
    height: 40px;
    line-height: 30px; }

#instant-callback-div .instant-switch {
  background: url("../images/handle.png") no-repeat scroll 0 0 white;
  box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.2);
  cursor: pointer;
  display: inline-block;
  height: 184px;
  vertical-align: top;
  width: 36px; }

#instant-callback-div .content-switcher {
  background-color: #fff;
  border-bottom-left-radius: 6px;
  display: inline-block;
  margin: 0 0 0 -4px;
  padding: 0 16px 12px;
  vertical-align: top;
  width: 245px; }

#instant-callback-div h4 {
  border-bottom: 1px solid #ededed;
  margin-bottom: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 16px; }

#instant-callback-div p {
  margin: 5px 0 !important;
  padding: 4px 0; }

#instant-callback-div input[type="submit"] {
  width: 95%; }

#instant-callback-div input, #instant-callback-div textarea {
  border: 1px solid #ccc;
  display: block;
  margin: 6px 0;
  padding: 6px 10px;
  width: 100%;
  height: 32px; }

#hide {
  background-color: #FFFFFF;
  border-radius: 0 5px 0 0;
  height: 32px;
  position: absolute;
  right: 7px;
  top: 7px;
  width: 40px;
  cursor: pointer;
  font-size: 12px;
  line-height: 13px;
  margin-bottom: 0; }

#hide img {
  opacity: 0.3;
  transition: all 0.3s ease 0s; }

#hide img:hover {
  opacity: 0.7; }

#speak-to-expert-div {
  background-color: rgba(230, 230, 230, 0.4);
  border: 1px solid #fff;
  border-radius: 5px;
  bottom: 1%;
  left: 1%;
  padding: 20px;
  position: absolute;
  width: 250px;
  z-index: 1; }

label.error {
  color: red;
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin-left: 5px; }

.labelText {
  color: #626262;
  font-size: 18px;
  margin: 0 0 6px;
  padding: 0; }

#speak-to-expert-div input {
  border: 1px solid #ccc;
  color: #3a3a3a;
  display: block;
  margin: 6px 0;
  padding: 6px 4%;
  width: 100%; }

#map-street {
  height: 510px; }

@media only screen and (max-width: 992px) {
  .menu-mobile.open ul.menu {
    display: block;
    top: 50px;
    min-width: 250px !important;
    width: 100%;
    position: absolute;
    right: 0px; } }
.bg-primary {
  background-color: #546E7A; }

.topbar-wrap .container {
  padding: 0px !important; }

#top-menu li {
  padding-right: 4px;
  padding-left: 4px; }
  #top-menu li a {
    color: #333;
    font-weight: 500;
    text-decoration: none; }
    #top-menu li a i {
      vertical-align: baseline;
      color: #607D8B; }
#top-menu .dropdown-menu a {
  color: #333; }

.cssmenu ul ul li:hover > a, .cssmenu ul ul li a:hover {
  color: white;
  background-color: #546E7A;
  border-left: 3px solid #03a9f4; }

.topbar-wrap {
  background-color: #FFF; }

@media (min-width: 768px) {
  section.header .topbar-wrap {
    padding-top: 0px; } }
@media only screen and (max-width: 768px) {
  section.header .container .menu-mobile i.menu-toggle {
    margin-top: 0px;
    margin-right: 0px;
    background: #999; } }
section.header .container .menu-mobile i.menu-toggle {
  background: #607D8B;
  height: 40px;
  width: 40px;
  line-height: 35px; }

@media (min-width: 320px) and (max-width: 480px) {
  section.header .owl-theme .owl-controls {
    display: none !important; } }

section.header .topbar-wrap {
  border-bottom: 1px solid #ddd; }

.inner-page {
  position: relative; }
  @media (min-width: 320px) and (max-width: 480px) {
    .inner-page {
      margin-top: 50px; } }
  @media (min-width: 768px) {
    .inner-page {
      margin-top: 95px; } }
